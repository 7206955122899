<template>
  <div id="BankCard" @click="onClick">
    <div class="card_content">
      <div class="status_box">{{ cardData.status_display | status | transactionStatus }}</div>
      <div class="card_t">{{ cardData.payment_type | paymentType }}</div>
      <div class="card_n">{{ cardData.card_number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankCard',
  props: {
    cardData: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$emit('Click')
    },
  },
}
</script>

<style lang="scss" scoped>
#BankCard {
  height: 160px;
  background: linear-gradient(180deg, #f18071 0%, #d96150 100%);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
    transform: translate3d(0, -4px, 0);
  }
  .card_content {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images-1/bank_card_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    position: relative;
    padding: 40px 20px;
    .status_box {
      position: absolute;
      top: 16px;
      @include rtl-sass-prop(right, left, 0);
      padding: 2px 10px;
      font-size: 12px;
      color: #ffa133;
      background-color: #fff6eb;
      @include rtl-sass-value(border-radius, 10px 0px 0px 10px, 0px 10px 10px 0px);
    }
    .card_t {
      font-size: 16px;
      color: $white;
      margin-bottom: 40px;
    }
    .card_n {
      font-size: 18px;
      color: $white;
      font-weight: 700;
    }
  }
}
</style>
