<template>
  <PageWrapper>
    <div class="add_bank">
      <el-popover placement="bottom" trigger="click">
        <div class="add_bank_popover">
          <el-button
            v-for="(method, index) in paymentMethods"
            :key="method.value"
            @click="onAddBank(method)"
            :data-testid="method.label"
            >{{ $t(method.label) }}</el-button
          >
        </div>
        <el-button type="primary" icon="el-icon-plus" slot="reference">{{ $t('common.button.addBankCard') }}</el-button>
      </el-popover>
    </div>
    <PagePanel>
      <div class="page_panel_inner">
        <h2 class="panel_title">{{ $t('common.field.unionPayCard') }}</h2>
        <el-row :gutter="16" v-if="bankData && bankData.length > 0" class="panel_card_list">
          <el-col
            :xs="24"
            :lg="6"
            class="panel_card_item"
            v-for="item in bankData"
            :key="item.index"
            :data-testid="handleTestId(item.payment_type)"
          >
            <BankCard ref="BankCardRef" :cardData="item" @Click="onShowDetail(item)"></BankCard>
          </el-col>
        </el-row>
        <Empty v-else>
          <div slot="description" class="empty_desc">
            <h2 class="desc_title">{{ $t('common.field.pleaseAddBankCard') }}</h2>
            <div class="desc_text">
              <span>{{ $t('common.field.singleClick') }}</span>
              <span class="special_span">{{ `【${$t('common.button.addBankCard')}】` }}</span>
              <span>{{ $t('common.field.add') }}</span>
            </div>
          </div>
        </Empty>
      </div>
    </PagePanel>

    <AddBankDrawer ref="addBankDrawerRef" @onSuccess="onSuccess"></AddBankDrawer>
    <SDialog :title="$t('common.field.tips')" :visible.sync="success" :center="true">
      <span>{{ resultMessage }}</span>
      <div slot="footer">
        <el-button type="primary" @click="success = false">{{ $t('common.button.ok') }}</el-button>
      </div>
    </SDialog>
    <BankDetail ref="bankDetailRef"></BankDetail>
  </PageWrapper>
</template>

<script>
import AddBankDrawer from '@/views/bankCardMange/components/AddBankDrawer.vue'
import BankCard from '@/views/bankCardMange/components/BankCard.vue'
import BankDetail from '@/views/bankCardMange/components/BankDetail.vue'
import { apiHistoryList } from '@/resource'

export default {
  components: {
    AddBankDrawer,
    BankCard,
    BankDetail,
  },
  data() {
    return {
      success: false,
      paymentMethod: '',
      paymentMethods: this.$config.paymentMethods,
      individualUserName: this.$store.state.common.individualUserName,
      resultMessage: '',
      bankData: [],
    }
  },
  mounted() {
    this.handleQueryData()
  },
  methods: {
    onSuccess(data) {
      this.success = true
      this.resultMessage = data.resultMessage
      this.$refs.BankCardRef.handleQueryData()
    },
    onAddBank(val) {
      this.$refs.addBankDrawerRef.visible = true
    },
    onShowDetail(row) {
      this.$refs.bankDetailRef.openDrawer(row)
    },
    handleQueryData() {
      apiHistoryList({
        cardType: -1,
      })
        .then(resp => {
          this.bankData = resp.data
        })
        .catch(err => {})
    },
    handleTestId(type) {
      if (type == 1) {
        return 'showDetail'
      } else if (type == 4) {
        return 'showUnionpayDetails'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/financeInfo.scss';
.add_bank {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}
.page_panel_inner {
  min-height: 650px;
  .panel_title {
    font-size: 16px;
    color: #09090d;
    font-weight: 700;
    padding-bottom: 16px;
  }
  .el-empty {
    height: 100%;
  }
}
.add_bank_popover {
  .el-button {
    width: 100%;
    border: none;
  }
}
.empty_desc {
  .desc_title {
    color: $text-primary;
    font-weight: 700;
    padding-bottom: 16px;
  }
  .desc_text {
    .special_span {
      color: $text-link;
    }
  }
}
.panel_card_list {
  .panel_card_item {
    margin-bottom: 16px;
  }
}
</style>
